/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Saira:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap'); */

/******
 font-family: 'Regular';
font-family: 'Bold';
font-family: 'Regular';
  font-family: Regular;
*******/
/* global.css */


/* Other global styles can go here */


body {
    font-family: 'HeadlineRegular';
    margin: 0 auto;
    width: 100%;
    background: #0B0B0B;
    overflow-x: hidden;
}

html {
    scroll-behavior: smooth;
}

a {
    text-decoration: inherit;
    color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
p,
ul,
li,
a,
label {
    margin: 0;
    padding: 0;
    color: #fff;
}

ul,
li {
    list-style: none;
}

.form-control:focus,
.custom-file-label {
    border: none !important;
}

p {
    word-break: break-word;
}

h2 {
    font-family: 'Merriweather', serif;
}

button:focus {
    box-shadow: none;
    outline: none;
}

a:hover,
.btn-link:hover,
.btn-link:focus {
    text-decoration: none;
}

::placeholder {
    color: #fff !important;
    /* Change this to your desired color */
}

select:focus {
    outline: none;
    box-shadow: none !important;
}

select option {
    color: #333 !important;
}

.btn {
    border-radius: 52px;
    background: #3BDE68;
    color: #043226;
    font-family: 'Bold';
    font-size: 16x;
    /* font-style: italic; */
    font-weight: 700;
    text-transform: uppercase;
    padding: 12px 28px;
    position: relative;
    z-index: 55;
}


/* .btn:hover, .btn:focus, .btn:active,.btn:visited, .btn:focus-visible, .btn-check:checked+.btn, .btn.active, .btn:first-child:active, :not(.btn-check)+.btn:active {
    background-color:#24a248;  
}   */

.btn:hover,
.btn:focus,
.btn:active {
    background-color: #24a248 !important;
}

.btn img {
    width: 100%;
    max-width: 25px;
    margin-left: 10px;
}

.center {
    text-align: center;
}

.btn.focus,
.btn:focus,
.form-control:focus,
.slick-slide:focus {
    outline: none;
    box-shadow: none;
    background-color: transparent;
}

p {
    color: #27282A;
    font-size: 16px;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
    max-width: 1350px;
    position: relative;
    z-index: 5;
}

.container-fluid {
    max-width: 1680px;
    position: relative;
    z-index: 5;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.main-menu {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
}

.navbar-div-with-language-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.menu-items .navbar-nav .nav-link {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 106.5%;
    text-transform: capitalize;
    margin: 0 10px;
}

.menu-items .navbar-nav .nav-item.active .nav-link {
    font-weight: 700;
    color: #fff;
}

.brand {
    padding: 10px 0px;
}

.menu-items .navbar-nav .nav-link:hover {
    color: #053a18;
}

.dropdown-menu-item .submenu {
    position: absolute;
    top: 100%;
    max-height: 0px;
    overflow-y: hidden;
    transition: 0.2s all;
    visibility: hidden;
    opacity: 0;
    background: #FFFFFF;
    border-radius: 0;
    min-width: 250px;
    left: 0;
}

.navbar .navbar-nav .submenu li {
    border-bottom: 1px solid #e2e2e2;
    border-right: none;
}

.menu-items .navbar-nav .submenu .nav-link {
    font-size: 14px;
    font-weight: 400;
    transition: 0.2s all;
}

.menu-items .navbar-nav .submenu .nav-link:hover {
    font-size: 14px;
    font-weight: 400;
    background: #dc531e;
    color: #fff;
    transition: 0.2s all;
}

.navbar .navbar-nav .submenu li:last-child {
    border-bottom: none;
}

.dropdown-menu-item .icon img {
    position: absolute;
    right: 7px;
    top: 17px;
    transition: 0.2s all;
}

.dropdown-menu-item {
    position: relative;
}

.selectbox-top select {
    background: #07182C;
    color: #fff;
    padding: 9px 18px;
    height: auto;
    border-radius: 0;
    border: none;
    font-weight: 600;
}

.menu-items .navbar-nav .nav-item>.nav-link.active {
    color: #dc531e;
}

.menu-items .navbar-nav .submenu .nav-link.active {
    background: #dc531e;
    color: #fff;
}

.selectbox-top {
    padding-left: 25px;
}

.main-menu .brand img {
    max-width: 419px;
    width: 100%;
    margin-left: -11px;
}

.website-header {
    min-height: 96px;
    display: flex;
    align-items: center;
    background: #087C32;
}

.topbar p {
    color: #042510;
    text-align: center;
    padding: 0px;
}

.topbar {
    background: #11C552;
}

.topbar p b {
    font-weight: 600;
}

.banner_featured_img img {
    width: 100%;
}

.banner_featured_img {
    position: relative;
}

.video_play_link {
    position: absolute;
    right: 90px;
    top: 20px;
    z-index: 9;
    max-width: 52px;
    cursor: pointer;
}

.quick_action_links li a img {
    width: 100%;
    max-width: 20px;
}

.quick_action_links li a {
    margin-left: 22px;
}

.grids {
    position: relative;
    padding-bottom: 60px;
}

.video_modal_ui .close {
    background: transparent;
    opacity: 1;
    border: none;
    filter: brightness(0);
    font-size: 40px;
    margin-top: -26px;
    margin-left: auto;
    display: block;
    height: 56px;
}

.grids:before {
    background: url(../images/background_grids.svg) repeat center / contain;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -2;
    content: "";
    opacity: 0.07;
}

.main_product_flash_sale_banner_sec {
    position: relative;
    margin-top: -10px;
}


.landing_page_banner {
    position: relative;
    z-index: 5;
    padding-top: 40px;
}

.main_product_text_bigger_img {
    position: absolute;
    top: -6px;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.6;
}

.main_product_text_bigger_img img {
    width: 100%;
}

.main_product_flash_img {
    position: absolute;
    top: -40px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
}

.main_product_flash_img img,
.main_product_flash_img img {
    width: 100%;
}


.product_big_img {
    width: 70%;
    padding: 20px;
    position: relative;
}

.product_price_image_features {
    display: flex;
}

.product_sale_price {
    width: 25%;
    padding-top: 138px;
}


.product_big_img img {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 121px;
    z-index: 5;
}


.product_sale_price>h1 {
    color: #FFF;
    font-family: 'Bold';
    font-size: 44px;

    font-weight: 700;
    line-height: 38px;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.product_sale_price>h1 span {
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
}

.product_calories {
    text-align: center;
    padding-top: 300px;
}

.product_calories h1 {
    color: #FFF;
    text-align: center;
    font-family: 'Bold';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 89.4%;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
}

h1 .text_bigger {
    color: #00DF97;
    text-align: center;
    font-family: 'Bold';
    font-size: 88px;
    font-style: normal;
    font-weight: 700;
    line-height: 72px;
    /* 89.472px */
    text-transform: uppercase;
}

.product_calories .text_smaler {
    font-size: 17px;
    line-height: 14px;
}

.product_calories h1 .text_medium {
    font-size: 22px;
}

.product_features_box {
    width: 100%;
    background: rgba(27, 26, 26, .9);
    padding: 20px;
    margin-top: 450px;
}

.product_features {
    width: 25%;
    display: flex;
    align-items: flex-end;
}

.product_features_box li img {
    width: 100%;
    max-width: 38px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.progress_bar_box span {
    font-size: 10px;
}

.progress_bar_box span:first-child {
    text-transform: uppercase;
}

.progress_bar_box {
    margin-bottom: 12px;
}

.progress_bar_box:last-child {
    margin-bottom: 0;
}

.progress-bar {
    background: #9dff85;
    border-radius: 100px;
}

.progress {
    background: #282828;
    height: 7px;
    margin-top: 4px;
}

.featured_img img {
    width: 100%;
}

.shop_now_btn_div {
    border-radius: 35px 35px 0px 0px;
    background: #043226;
    padding: 12px;
    margin-top: 30px;
}

.product_explore_box {
    background: linear-gradient(180deg, #043226 0%, #042018 82.29%);
    position: relative;
    z-index: 6;
    padding: 20px;
    border-radius: 35px 0px 35px 35px;
    margin-bottom: 80px;
}

.very_big_title {
    text-align: center;
    color: #FFF;
    text-align: center;
    font-family: 'Bold';
    font-size: 74px;
    /* font-style: italic; */
    font-weight: 700;
    line-height: 52px;
    /* 75.99px */
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: -120px;
}

.very_big_title span {
    font-size: 40px;
    font-weight: 500;
}

.product_description p {
    color: #89FFDE;
    text-align: justify;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 30px;
}


.hurdle_layer img {
    position: absolute;
    bottom: 0;
    left: 80px;
    right: 0;
    width: 72%;
    margin: 0 auto;
    z-index: -1;
    opacity: 0.1;
}

.margin_bottom_adjust {
    position: relative;
    bottom: -50px;
}

.margin_bottom_adjust .btn {
    font-size: 22px;
}

.heighlight_right_light {
    position: absolute;
    right: 0;
    bottom: -680px;
}

.contact_us_section .heighlight_right_light {
    bottom: -410px;
}

.product_features_box ul li:last-child {
    flex-grow: 1;
    text-align: right;
}

.product_features_box ul li p {
    color: #FFF;
    text-align: center;
    font-family: Regular;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 89.4%;
    /* 10.728px */
    text-transform: uppercase;
    max-width: 60px;
    margin-left: auto;
    padding-bottom: 10px;
}

.product_features_box ul {
    padding-bottom: 12px;
}

.product_features_box ul li p span {
    display: block;
    font-weight: 400;
    padding-bottom: 2px;
}

.heighlight_right_light img {
    width: 100%;
    max-width: 590px;
}

.heighlight_left_light img {
    width: 100%;
    max-width: 440px;
}

.heighlight_left_light {
    position: absolute;
    left: 0;
    top: 480px;
}

.main_title {
    color: #FFF;
    font-family: 'Bold';
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 89.4%;
    text-transform: uppercase;
}

.product_more_fuel_sec .main_title {
    text-align: center;
}

.border_bottom_1px {
    padding-bottom: 20px; 
    margin-bottom: 50px;
    border-bottom: 1px solid #fff;
}

.nav-pills .nav-item .nav-link {
    color: #FFF;
    font-family: 'Bold';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 89.4%;
    text-transform: uppercase;
    border-radius: 200px;
}

.nav-pills .nav-item .nav-link.active {
    background: #087C32;
}

.product_box {
    background: #fff;
    border: 1px solid #000;
    border-radius: 15px;
    margin: 0px 30px;
    padding: 15px;
    transition: all .2s;
   height:100%;
}

.product_img img {
    width: 100%;
}

.product_img img {
    width: 100%;
    border-radius: 12px;
}

.spacing {
    width: 20%;
    height:100%;
}
.product_name a {
    color: #1E1E1E;
    font-family: 'Regular';
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.productt_quantity {
    color: #484848;
    font-family: 'Regular';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 3px;
}

.regular_price {
    color: #434343;
    font-family: 'Regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 94.023%;
    text-decoration: line-through;
    display: block;
}

.sale_price {
    color: #1E1E1E;
    font-family: 'Regular';
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 94.023%;
    margin-top: 6px;
    display: inline-block;
}

.add_to_cart_btn {
    border-radius: 5px;
    background: #087C32;
    color: #FFF;
    font-family: 'Regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 2px 16px;
    display: flex;
    align-items: center;
    transition: 0.2s all;
}

.add_to_cart_btn:hover {
    background: #087C32;
    color: #FFF;
}

.add_to_cart_btn img {
    margin-left: 7px;
    max-width: 22px;
    margin-top: 2px;
}

.product_img {
    position: relative;
    margin-bottom: 10px;
    border-radius: 12px;
}



.product_discount {
    position: absolute;
    top: 8px;
    right: 5px;
    z-index: 3;
    color: #FFF;
    text-align: center;
    font-family: 'Regular';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 86.523%;
    letter-spacing: -1.04px;
    text-transform: uppercase;
    border-radius: 6px;
    background: #087C32;
    padding: 6px 12px;
}

.more_options_btn a {
    width: 10px;
    display: inline-block;
    text-align: right;
}

.more_options_btn a img {
    max-width: 4px;
}

.more_fuel_for_file_sec {
    position: relative;
    padding-left: 65px;
    padding-right: 65px;
    padding-top: 60px;
}

.more_fuel_for_file_sec .slick-prev {
    left: -50px;
    background: url(../images/arrow_right_icon.svg) no-repeat scroll center / contain;
    width: 50px;
    height: 50px;
    transform: rotate(180deg);
    filter: brightness(0) invert(0.6);
}

.more_fuel_for_file_sec .slick-next {
    right: -50px;
    background: url(../images/arrow_right_icon.svg) no-repeat scroll center / contain;
    width: 50px;
    height: 50px;
    filter: brightness(0) invert(0.6);
    margin-top: 26px;
}

.more_fuel_for_file_sec .slick-prev:before,
.more_fuel_for_file_sec .slick-next:before {
    font-size: 0;
}

.more_fuel_for_file_sec .explore_more_btn {
    min-height: 40px;
    line-height: 32px;
}

.view_more_btn {
    min-height: 40px;
    line-height: 32px;
}

.shop_your_way_sec .container {
    max-width: 1140px;
}

.more_fuel_for_file_sec .tab-content>.tab-pane {
    display: block !important;
    max-height: 0;
    opacity: 0;
    /* transition: 0.1s all; */
    visibility: hidden;
}

body .more_fuel_for_file_sec .tab-content>.active {
    display: block !important;
    max-height: 2000px;
    opacity: 1;
    /* transition: 0.15s all; */
    visibility: visible;
}


.why_carabao_section {
    padding-top: 100px;
    position: relative;
}

.why_carabao_section .main_title {
    font-family: 'Bold';
    font-size: 73px;

    font-weight: 700;
    line-height: 89.4%;
    /* 107.28px */
    text-transform: uppercase;
    margin-bottom: 70px;
    color: #000;
    text-shadow: 2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 2px 2px #fff, -2px -2px #fff, 2px -2px #fff, -2px 2px #fff;
}


.why_carabao_section .box_icon_with_text {
    display: flex;
    align-items: center;
    max-width: 230px;
}

.why_carabao_section .box_icon_with_text img {
    width: 100%;
    min-width: 30px;
    max-width: 32px;
    margin-right: 22px;
}

.company_logos img {
    max-width: 240px;
    margin: 0 auto;
    display: block;
}

.why_carabao_section .carbas_list_with_icons li {
    width: 100%;
    margin-bottom: 50px;
}

.why_carabao_section .box_icon_with_text h5 {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
}

.why_carabao_section .carbas_list_with_icons {
    margin-bottom: 0px;
}

.blog_box {
    padding: 16px;
    position: relative;
}

.our_blogs_sec .slick-prev {
    right: 68px;
    background: url(../images/arrow_right_icon.svg) no-repeat scroll center / contain;
    width: 34px;
    height: 32px;
    transform: rotate(180deg);
    left: auto;
    top: -71px;
    border-radius: 3px;
    background-color: #3BDE68;
}

.our_blogs_sec .slick-next {
    right: 20px;
    background: url(../images/arrow_right_icon.svg) no-repeat scroll center / contain;
    width: 34px;
    height: 32px;
    left: auto;
    top: -55px;
    border-radius: 3px;
    background-color: #3BDE68
}

.our_blogs_sec .slick-prev:before,
.our_blogs_sec .slick-next:before {
    font-size: 0;
}


.shop_your_way_heading span {
    color: #3BDE68;
    font-family: 'Bold';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 89.4%;
    text-transform: uppercase;
}

.shop_your_way_heading h2 {
    color: #FFF;
    font-family: 'Bold';
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 89.4%;
    text-transform: uppercase;
    padding-top: 4px;
}

section.shop_your_way_sec {
    padding-top: 100px;
    padding-bottom: 100px;
}

.our_blogs_sec .blog_tile {
    color: #FFF;
    font-family: 'Regular';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 89.4%;
    text-transform: uppercase;
    text-align: center;
    padding-top: 10px;
}

.carabao_cup_section .featured_img img {
    width: 100%;
    margin-bottom: 120px;
}

.our_blogs_sec {
    padding-bottom: 60px;
}

.instagram_feed_sec {
    display: flex;
    flex-wrap: wrap;
}

.instagram_thumbnail_box {
    position: relative;
}

.instagram_feed_sec .spacing {
    width: 20%;
    padding: 16px;
}

.instagram_thumbnail_box a {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 2;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: 0.2s all;
    border-radius: 14px;
}

.instagram_thumbnail_box:hover a {
    opacity: 1;
    transition: 0.2s all;
}

.instagram_thumbnail_box a img {
    max-width: 44px;
}

.customer_feedback_sec .title {
    color: #FFF;
     font-family: Regular;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.856px;
}

.customer_feedback_sec .sub_title {
    color: #FFF;
     font-family: Regular;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.588px;
    padding-top: 10px;
}

.customer_feedback_box {
    border-radius: 5.347px;
    border: 1.337px solid #EFEFEF;
    background: #043226;
    width: 100%;
    padding: 20px;
    height: 100%;
}

.customer_feedback_box .customer_heading {
    display: flex;
    align-items: start;
    justify-content: space-between;
}

.customer_feedback_box .customer_rating {
    padding-top: 10px;
    display: flex;
    align-items: center;
}

.customer_feedback_box .customer_name {
    color: #FFF;
     font-family: Regular;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.588px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.customer_feedback_box .customer_comment {
    color: #FFF;
     font-family: Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.428px;
}

.customer_feedback_slider .spacing {
    width: 20%;
    padding: 12px;
    height: auto;
}

.customer_feedback_slider {
    margin-top: 40px;
}

.customer_feedback_slider .slick-track {
    display: flex;
}

.customer_feedback_box .customer_rating img {
    max-width: 28px;
}

.customer_feedback_slider .slick-next.slick-arrow {
    color: #FFF;
     font-family: Regular;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.107px;
    width: 110px;
    border-radius: 5.347px;
    border: 1.337px solid #FFF;
    height: 42px;
    padding-right: 40px;
    z-index: 99;
    top: -75px;
    right: 10px;
}

.customer_feedback_slider .slick-prev.slick-arrow {
    color: #FFF;
    font-family: Regular;
    left: auto;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.107px;
    width: 140px;
    border-radius: 5.347px;
    border: 1.337px solid #FFF;
    height: 42px;
    padding-left: 0px;
    z-index: 99;
    top: -75px;
    right: 135px;
    padding-left: 16px;
}

.customer_feedback_slider .slick-next:before {
    background: url('../images/green_right_arrow.svg') no-repeat scroll center / contain;
    width: 30px;
    height: 30px;
    opacity: 1;
    left: 63px;
    position: absolute;
    font-size: 0;
    top: 5px;
}

.customer_feedback_slider .slick-prev:before {
    background: url(../images/green_right_arrow.svg) no-repeat scroll center / contain;
    width: 30px;
    height: 30px;
    opacity: 1;
    left: 9px;
    position: absolute;
    font-size: 0;
    top: 5px;
    transform: rotate(180deg);
}

.customer_feedback_slider .slick-dots li button {
    width: 10px;
    height: 10px;
    background: #364806;
    border-radius: 100%;
}

.customer_feedback_slider .slick-dots li.slick-active button:before {
    display: none;
}

.customer_feedback_slider .slick-dots .slick-active button {
    background: #9BD609;
}

.customer_feedback_slider .slick-dots li {
    margin: 0 0px;
}

.get_ready_game_sec .heighlight_right_light {
    bottom: auto;
    top: -100%;
}

.get_ready_game_sec {
    background: url(../images/get_ready_game_img.png) no-repeat scroll center / cover;
    padding-top: 200px;
    padding-bottom: 200px;
}

.get_ready_game_content h2 {
    color: #FFF;
    text-shadow: 0px 9px 0px #000;
    font-family: 'Bold';
    font-size: 72px;

    font-weight: 700;
    line-height: 89.4%;
    text-transform: uppercase;
}

.why_carabao_section .why_carb_light_left img {
    position: absolute;
    left: 0;
    top: -130px;
    max-width: 410px;
    width: 100%;
    height: auto;
}

.contact_us_heading h2 {
    color: #FFF;
    font-family: 'Regular';
    font-size: 44px;
    font-style: normal;
    font-weight: 600;
    line-height: 119.25%;
}

.contact_us_heading p {
    color: #FFF;
    font-family: 'Regular';
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    padding-top: 13px;
}

.form-control {
    background: transparent;
    border: none;
    border-bottom: 1px solid #fff;
    border-radius: 0;
    color: #F2F2F2;
    font-family: 'Regular';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-left: 0;
    padding-right: 0;
}

.form-control:focus {
    border-bottom: 1px solid #fff !important;
    color: #F2F2F2;
}

.contact_us_form .form-select {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #fff;
    border-radius: 0;
    color: #F2F2F2;
    font-family: 'Regular';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    height: 36px;
    filter: brightness(0) invert(1);
}

.contact_us_section .contact_height_light_img img {
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 1px;
}

.contact_us_section {
    padding-top: 0px;
    padding-bottom: 140px;
    position: relative;
    overflow: hidden;
}


.contact_us_section .container {
    max-width: 1140px;
}

.main_footer .container {
    max-width: 1180px;
}

.main_footer {
    border-radius: 70px 70px 0px 0px;
    background: #042F23;
    overflow: hidden;
    border-bottom: 8px solid #03251C;
    position: relative;
    z-index: 10;
    margin-top: -60px;
}

.main_footer .footer_layer img {
    position: absolute;
    bottom: 0;
    right: 0;
    opacity: 0.1;
    max-width: 780px;
}

.footer_logo img {
    width: 100%;
    max-width: 270px;
    margin-bottom: 40px;
}

.footer_widget .footer_title {
    font-size: 20px;
    margin-bottom: 14px;
    margin-top: 38px;
}

.footer_widget .quick_links a {
    font-size: 15px;
    margin-bottom: 5px;
    display: inline-block;
}

ul.social_media_links {
    display: flex;
}

ul.social_media_links li {
    padding-bottom: 10px;
    padding-right: 10px;
}

.footer_widget .quick_links a:hover {
    color: #e9e9e9;
}


@-webkit-keyframes marquee {
    0% {
        text-indent: 100%;
    }

    100% {
        text-indent: -100%;
    }
}

.website-marquee .li {
    width: 100%;
}

.website-marquee {
    background: linear-gradient(90deg, #11C552 0%, #11C552 100%);
    padding-top: 4px;
}

.website-marquee ul {
    display: flex;
    align-items: center;
}

.website-marquee ul li {
    color: #042510;
    text-align: center;
    font-family: 'Regular';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    padding: 2px 2px;
    display: inline-block;
    white-space: nowrap;
}

.website-marquee li img {
    max-width: 15px;
    margin-right: 5px;
}

.contact_us_heading {
    margin-top: 30px;
}

.product_page_banner_imgs img {
    width: 100%;
}

section.product_page_banner {
    position: relative;
    overflow: hidden;
}

.product_page_banner .main_product_text_bigger_img {
    top: -20px;
    z-index: 2;
}

.header_banner_cover {
    position: relative;
    z-index: 999;
}

.product_page_banner .product_page_banner_imgs .banner_flash_img {
    position: absolute;
    top: -97px;
    right: 0px;
    transform: scale(1);
    z-index: -1;
    margin-left: -300px;
}

.product_page_banner_imgs {
    position: relative;
    min-height: 400px;
}

.product_page_banner .main_product_bottle_big img {
    max-width: 460px;
    position: absolute;
    left: 50%;
    transform: translateX(-67%);
    top: 60px;
}

.product_banner_content p {
    color: #FFF;
    font-family: 'Regular';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 126.923% */
    max-width: 380px;
    padding-top: 11px;
}

.product_banner_content h2 {
    color: #3BDE68;
    font-family: 'Bold';
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 89.4%;
    /* 85.824px */
    text-transform: uppercase;
}

.product_banner_content h5 {
    color: #FFF;
    font-family: 'Bold';
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 89.4%;
    /* 39.336px */
    text-transform: uppercase;
    padding-bottom: 13px;
}

.product_banner_content {
    padding-top: 60px;
}

.product_banner_content .btn {
    min-height: 50px;
    margin-top: 30px;
    font-size: 20px;
}

.product_box .add_to_wishlist {
    position: absolute;
    top: 10px;
    left: 10px;
}

.product_img>img {
    min-height: 220px;
    object-fit: cover;
}

.product_box:hover {
    border: 1px solid #000;
    background: #0EB34A;
    transition: 0.2s all;
}

.product_box .product_img {
    background: #9af801;
    transition: 0.2s all;
}

.product_box:hover p,
.product_box:hover span,
.product_box:hover h4 {
    color: #fff;
    transition: 0.2s all;
}

.product_box:hover .more_options_btn a img {
    filter: brightness(0) invert(1);
    transition: 0.2s all;
}

.product_box:hover .add_to_cart_btn {
    border-radius: 200px;
    background: #08431D;
    transition: 0.2s all;
}

.product_box:hover .add_to_cart_btn img {
    filter: invert(1);
    transition: 0.2s all;
}

.more_fuel_product_list {
    display: flex;
    flex-wrap: wrap;
}

.more_fuel_product_list .spacing {
    width: 33.33%;
}

.feel_freshing_section_bg {
    background: rgba(0, 0, 0, 0.70);
    margin-top: 90px;
    padding: 30px;
    position: relative;
    z-index: 20;
    border-radius: 20px;
}

.feel_freshing_heading {
    text-align: center;
    margin-bottom: 60px;
}

.feel_freshing_heading h2 {
    color: #FFF;
    font-family: 'Bold';
    font-size: 40px;

    font-weight: 700;
    line-height: 89.4%;
    /* 57.216px */
    text-transform: uppercase;
}

.feel_freshing_heading h3 {
    color: #00D80F;
    font-family: 'Bold';
    font-size: 35px;

    font-weight: 700;
    line-height: 89.4%;
    /* 44.7px */
    text-transform: uppercase;
}

.feel_freshing_description_box h3 {
    color: #FFF;
    font-family: 'Bold';
    font-size: 40px;

    font-weight: 700;
    line-height: 89.4%;
    text-transform: uppercase;
}

.feel_freshing_description_box .sub_heading {
    color: #00D80F;
    font-family: 'Bold';
    font-size: 15px;

    font-weight: 500;
    line-height: 89.4%;
    /* 21.456px */
    text-transform: uppercase;
    margin-bottom: 50px;
    display: inline-block;
}

.feel_freshing_description_box p {
    color: #0B8765;
    text-align: justify;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 30px;
}

.feel_freshing_description_box .btn.add_to_cart_b {
    line-height: 48px;
    min-height: 50px;
}

.feel_the_freshing_section {
    position: relative;

}


.freshing_green_light_right {
    width: 100%;
    position: absolute;
    top: -810px;
    z-index: -1;
    right: 0;
    max-width: 720px;
    display: inline-block;
    transform: rotate(180deg);
}

.freshing_green_light_left {
    width: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
    left: 0;
    max-width: 560px;
    display: inline-block;
    top: 0px;
}

.freshing_green_light_left img {
    max-width: 660px;
}

.freshing_green_light_right img {
    width: 100%;
    height: 100%;
}

.newsletter_description {
    padding-bottom: 50px;
}

.why_carabao_section.product_why_carabao .main_title.never_ending_energy_heading {
    color: #FFF;
    font-family: 'Bold';
    font-size: 60px;

    font-weight: 700;
    line-height: 89.4%;
    text-transform: uppercase;
    text-shadow: none;
    max-width: 450px;
}

.why_carabao_section.product_why_carabao .carbas_list_with_icons li {
    width: 50%;
}



.green_text_list .box_icon_with_text h5 {
    color: #3BDE68;
    font-family: 'Regular';
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
}

.our_newsletter_section {
    background: url(../images/newsletter_background.png) no-repeat scroll center / cover;
    padding-bottom: 10px;
    padding-top: 30px;
    margin-top: 100px;
}


.newsletter_description h2 {
    color: #FFF;
    font-family: 'Bold';
    font-size: 58px;

    font-weight: 700;
    line-height: 89.4%;
    /* 85.824px */
    text-transform: uppercase;
    padding-top: 30px;
}

.newsletter_description p {
    color: #FFF;
    font-family: 'Regular';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 103.125% */
    max-width: 770px;
    padding-top: 14px;
}

.newsletter_form_box .form-control {
    background: transparent;
    border: none;
    border-bottom: 2px solid #07FF00;
    border-radius: 0;
    height: 60px;
    font-size: 20px;
    color: #fff;
    margin-right: 30px;
}

.newsletter_form_box .form-control:focus,
.newsletter_form_box .form-control:active {
    border-bottom: 2px solid #07FF00 !important;
}

.newsletter_form_box .btn {
    height: 10px;
    min-height: 60px;
    white-space: nowrap;
}

.best_seller_section {
    position: relative;
    overflow: hidden;
}

.best_seller_section .freshing_green_left_light img {
    position: absolute;
    top: -660px;
    left: 0;
    width: 100%;
    max-width: 650px;
}

.best_seller_section .freshing_green_right_light img {
    position: absolute;
    right: 0;
    bottom: -330px;
    max-width: 610px;
    width: 100%;
    transform: rotate(180deg);
}

.home_page_banner_content {
    position: absolute;
    width: 100%;
    max-width: 1040px;
    top: 46%;
    margin: 0 auto;
    display: block;
    left: 50%;
    transform: translate(-50%, -50%);
}

.home_page_banner_content p {
    color: #11C552;
    font-family: 'Bold';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 102.31%;
    text-transform: uppercase;
    max-width: 230px;
    padding-bottom: 13px;
}

.home_page_banner_content h1 {
    color: #FFF;
    font-family: 'Bold';
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 90.505%;
    text-transform: uppercase;
}

.home_page_banner_content .text_stroke {
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
}

.carabao_cup_section h5 {
    color: #89FFDE;
    text-align: center;
    font-family: 'Bold';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 89.4%;
    /* 28.608px */
    text-transform: uppercase;
}

.carabao_cup_section h2 {
    color: #FFF;
    text-align: center;
    font-family: 'Bold';
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 89.4%;
    /* 85.824px */
    text-transform: uppercase;
    padding-top: 10px;
}

.carabao_cup_section p {
    color: #FFF;
    text-align: center;
    font-family: Regular;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    /* 36px */
    text-transform: capitalize;
    max-width: 720px;
    margin: 0 auto;
    padding-top: 14px;
    padding-bottom: 30px;
}


/************************* About Us Page Css Start *******************************/

.about_us_banner_section {
    background: url(../images/about_page_banner_img.png) no-repeat scroll top / cover;

}

.product_page_banner {
    min-height: 700px;
}

.our_story_box {
    background: #043226;
    border-radius: 30px;
    margin-top: -70px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 50px;
    margin-bottom: 220px;
    position: relative;
    z-index: 99;
}

.our_story_box h3 {
    color: #FFF;
    font-family: 'Bold';
    font-size: 50px;

    font-weight: 700;
    line-height: 89.4%;
    text-transform: uppercase;
}

.our_story_box p {
    color: #89FFDE;
    text-align: center;
    font-family: 'Regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-top: 20px;
}

.our_story_images_adjust {
    position: relative;
    margin-top: 30px;
}

.our_story_images_adjust .zigzag_img img {
    opacity: 0.2;
    margin-top: 160px;
    width: 100%;
    max-width: 1040px;
}

.our_story_images_adjust .out_story_product_img {
    position: absolute;
    top: -60px;
    max-width: 840px;
    right: -30px;
    transform: translateX(0%);
}

.champion_cup img {
    position: absolute;
    top: 70px;
    max-width: 580px;
    left: 10px;
    transform: translateX(0%);
}

.our_story_images_adjust .out_story_product_img img {
    width: 100%;
}

.our_mission_vision_content .main_title {
    color: #FFF;
    font-family: 'Bold';
    font-size: 44px;

    font-weight: 700;
    line-height: 89.4%;
    text-transform: uppercase;
    max-width: 250px;
    margin-bottom: 30px;
}

.our_mission_vision_content p {
    color: #FFF;
    font-family: 'Regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 126.923% */
    margin-bottom: 30px;
    margin-top: 0px;
}

.our_vison_and_mission_section .container {
    max-width: 1250px;
}

.light_left,
.light_right {
    position: absolute;
    z-index: -1;
}

.our_story_section,
.our_vison_and_mission_section {
    position: relative;
}

.our_story_section .light_left img {
    width: 100%;
    max-width: 580px;
}

.our_story_section .light_right {
    right: 0;
    bottom: -150px;
    transform: rotate(180deg);
}

.our_story_section .light_right img {
    width: 100%;
    max-width: 540px;
}

.our_story_section .light_left {
    left: -80px;
    top: -320px;
}

.our_vison_and_mission_section .light_left img {
    width: 100%;
    max-width: 490px;
}

.our_vison_and_mission_section .light_left {
    top: -350px;
}

.our_vison_and_mission_section {
    padding-top: 50px;
}

.why_carabao_about_page .light_left {
    right: 0;
    top: -300px;
    transform: rotate(180deg);
}

.why_carabao_about_page .light_left img {
    max-width: 430px;
}

/************************* About Us Page Css End *******************************/

.why_carabao_section.product_why_carabao {
    padding-top: 10px;
}

.why_carabao_section.product_why_carabao .box_icon_with_text img {
    min-width: 50px;
    max-width: 72px;
}

.feel_freshing_section_bg_sec h3,
.feel_freshing_section_bg_sec h2 {
    font-style: normal;
    padding-top: 5px;
}

.feel_freshing_section_bg_sec p {
    color: #FFF;
    text-align: center;
    font-family: Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.5px;
    letter-spacing: -0.2px;
    max-width: 840px;
    margin: 0 auto;
    padding-top: 10px;
}

.feel_freshing_section_bg_sec {
    margin-bottom: 130px;
}


.feel_freshing_section_bg_sec .freshing_green_light_left {
    top: -380px;
}

.feel_freshing_section_bg_sec .feel_freshing_heading {
    margin-bottom: 30px;
}

.distribution_sec_banner {
    background: none;
}

.distribution_sec_banner .main_product_text_bigger_img {
    opacity: 1;
}


.join_our_winning_team_section .contact_us_form {
    background: #000;
    border-radius: 20px;
    margin-top: -360px;
    position: relative;
    z-index: 5;
    margin-bottom: 280px;
    padding: 100px;
}

.join_our_winning_team_section .floating_bottle img {
    position: absolute;
    left: -56px;
    max-width: 130px;
    top: 140px;
}

.join_our_winning_team_section .floating_bottle.right_bottle img {
    left: auto;
    right: -50px;
    bottom: -100px;
    width: 100%;
    top: auto;
    transform: rotate(18deg);
}

.join_our_winning_team_section .contact_heading h5 {
    color: #00D80F;
    font-family: 'Bold';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 89.4%;
    /* 32.184px */
    text-transform: uppercase;
}

.join_our_winning_team_section .contact_heading h2 {
    color: #FFF;
    font-family: 'Bold';
    font-size: 54px;
    font-style: normal;
    font-weight: 700;
    line-height: 89.4%;
    /* 85.824px */
    text-transform: uppercase;
}

.join_our_winning_team_section .contact_heading p {
    color: #FFF;
    text-align: center;
    font-family: Regular;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    /* 118.75% */
    letter-spacing: -0.32px;
    max-width: 620px;
    margin: 0 auto;
    padding-top: 5px;
}

.join_our_winning_team_section .contact_heading {
    text-align: center;
}


.join_our_winning_team_section .trade_enquery_heading {
    text-align: center;
}

.join_our_winning_team_section .trade_enquery_heading h6 {
    color: #00D80F;
    text-align: center;
    font-family: Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 23.5px;
    /* 146.875% */
    letter-spacing: -0.16px;
    margin-top: 50px;
}

.join_our_winning_team_section .trade_enquery_heading h4 {
    color: #FFF;
    font-family: 'Bold';
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 89.4%;
    text-transform: uppercase;
    padding-top: 5px;
}

.join_our_winning_team_section .our_story_section .light_right {
    bottom: -450px;
}

.join_our_winning_team_section .floating_carabao_cup_tag img {
    position: absolute;
    top: -80px;
    right: 70px;
}

.our_story_section.join_our_winning_team_section {
    padding-left: 70px;
    padding-right: 70px;
}

.our_story_section.join_our_winning_team_section .light_left {
    left: -80px;
    top: 30px;
}


/*************************************** Influencer page css start ******************************/

.influencer_banenr_sec {
    background: transparent;
}

.influencer_flash_left img {
    position: absolute;
    top: -80px;
    left: -160px;
    width: 100%;
    max-width: 340px;
    z-index: 9;
}

.influencer_flash_right img {
    position: absolute;
    bottom: 140px;
    right: -160px;
    width: 100%;
    max-width: 340px;
    z-index: 9;
}

.welcome_influencer_sec .sub_heading_text {
    color: #FFF;
    font-family: 'Bold';
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 89.4%;
    /* 42.912px */
    text-transform: uppercase;
    width: 100%;
    margin-top: 30px;
    display: inline-block;
}

.welcome_influencer_sec h2 {
    color: #3BDE68;
    font-family: 'Bold';
    font-size: 74px;
    font-style: normal;
    font-weight: 700;
    line-height: 89.4%;
    text-transform: uppercase;
}

.welcome_influencer_sec p {
    color: #FFF;
    text-align: justify;
    font-family: Regular;
    font-size: 31px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    margin-top: 30px;
}

.welcome_influencer_sec {
    margin-top: -150px;
}

.influencer_why_carabao_sec .main_title {
    font-family: 'Bold';
    font-size: 74px;

    font-weight: 700;
    line-height: 89.4%;
    text-transform: uppercase;
    margin-bottom: 70px;
    color: #000;
    text-shadow: 2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 2px 2px #fff, -2px -2px #fff, 2px -2px #fff, -2px 2px #fff;
}

.influencer_why_carabao_sec {
    padding-top: 120px;
}

.reasons_why_carabao_list {
    list-style: none;
    counter-reset: item;
    display: flex;
    flex-wrap: wrap;
    max-width: 1000px;
}

.reasons_why_carabao_list li {
    counter-increment: item;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    width: 50%;
    padding-right: 50px;
    padding-left: 50px;
    position: relative;
    border-right: 1px solid #373737;
    border-top: 1px solid #373737;
}

.reasons_why_carabao_list li:nth-child(even) {
    border-right: none;
}

.reasons_why_carabao_list li h2 {
    color: #00D80F;
    font-family: Regular;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 45px;
}

.reasons_why_carabao_list li:nth-child(odd) {
    padding-left: 0;
}

.reasons_why_carabao_list li:nth-child(1),
.reasons_why_carabao_list li:nth-child(2) {
    border-top: none;
}

.reasons_why_carabao_list li:before {
    margin-right: 20px;
    content: counter(item);
    color: white;
    text-align: center;
    display: inline-block;
    background: -webkit-linear-gradient(-86deg, #00D80F 5%, #00D80F 05%, #000 91%);
    -webkit-background-clip: text;
    -webkit-text-stroke: 6px transparent;
    color: #0b0b0b;
    font-family: 'Bold';
    font-size: 120px;
    font-weight: 700;
}

.influencer_why_carabao_sec .light_right {
    position: absolute;
    top: -200px;
    right: -150px;
    max-width: 500px;
}

.influencer_why_carabao_sec {
    position: relative;
    overflow: hidden;
}

.influencer_why_carabao_sec .light_right img {
    width: 100%;
}


.influencer_form {
    border-radius: 33px;
    background: linear-gradient(180deg, #032018 0%, #000 100%);
    padding: 40px;
}

.influencer_how_to_join_sec {
    padding-top: 120px;
}

.influencer_how_to_join_sec .header_div h2 {
    color: #3BDE68;
    font-family: 'Bold';
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    line-height: 89.4%;
    text-transform: uppercase;
    max-width: 320px;
    width: 100%;
    min-width: 320px;
    padding-right: 50px;
}

.influencer_how_to_join_sec .header_div p {
    color: #FFF;
    text-align: justify;
    font-family: Regular;
    font-size: 28px;
    font-style: normal;
    font-weight: 300;
    line-height: 33px;
}

.influencer_how_to_join_sec .header_div b {
    font-weight: 500;
}

.influencer_how_to_join_sec .influencer_form .header_div {
    display: flex;
    align-items: center;
}

.influencer_our_requirements_sec .influencer_requirement_row {
    display: flex;
}

.influencer_our_requirements_sec .influencer_requirement_row .main-title {
    color: #FFF;
    font-family: Regular;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 37.5px;
    max-width: 460px;
    padding-right: 100px;
}

.influencer_our_requirements_sec .requirement_list_box {
    display: flex;
    flex-wrap: wrap;
}

.influencer_our_requirements_sec .requirement_list_box li {
    width: 50%;
    padding: 10px 32px;
    position: relative;
}

.influencer_our_requirements_sec .requirement_list_box li:before {
    position: absolute;
    left: 14px;
    top: 22px;
    content: "";
    width: 3px;
    height: 3px;
    background: #fff;
    border-radius: 100%;
}

.influencer_our_requirements_sec {
    padding-top: 40px;
    padding-bottom: 80px;
}

.become_trail_brezzer {
    background: url(../images/become_trail_brazer_bg.png) no-repeat scroll right / cover;
    padding-top: 80px;
    padding-bottom: 180px;
}

.become_trail_brezzer_box .main-title {
    color: #1E1E1E;
    font-family: 'Bold';
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 89.4%;
    /* 42.912px */
    text-transform: uppercase;
    max-width: 390px;
}

.become_trail_brezzer_box .main-title span {
    color: #087C32;
}

.become_trail_brezzer_box p {
    color: #1E1E1E;
    font-family: Regular;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px;
    padding-top: 20px;
    padding-bottom: 30px;
}

.become_trail_brezzer_box .join_now_btn {
    background: #087C32;
    color: #fff;
    font-style: normal;
    padding: 5px 60px;
    line-height: 10px;
    min-height: 50px;
}

/*************************************** Influencer page css End ******************************/
.our_story_section.login_form_area {
    margin-top: -310px;
    padding-bottom: 120px;
}

.login_form_area .login_form {
    position: relative;
    background: linear-gradient(180deg, #043226 0%, #042018 82.29%);
    z-index: 6;
    padding: 20px;
    border-radius: 35px;
    margin-bottom: 80px;
}

.login_form_img img {
    width: 100%;
}

.remember_password_box {
    position: relative;
    padding-left: 30px;
}

.remember_password_box .custom_checkbox {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
}

.remember_password_box label:before {
    position: absolute;
    top: 7px;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 3px;
    background: #fff;
    content: "";
}

.remember_password_box .custom_checkbox:checked~label:after {
    position: absolute;
    top: 11px;
    left: 3px;
    height: 8px;
    width: 13px;
    border-radius: 0;
    background: transparent;
    content: "";
    border-left: 2px solid #000;
    border-bottom: 2px solid #000;
    transform: rotate(-56deg);
}


.remember_password_box label {
    color: #00D80F;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    cursor: pointer;
}

.forgot_password_link {
    color: #00D80F;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.login_form_area .form-control {
    border-color: #07FF00;
    height: 52px;
}

.login_form_area .btn.view_more_btn {
    padding: 13px 50px;
}

.social_login_box.text-center h4 {
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 40px;
}

.social_login_box img {
    width: 100%;
    max-width: 40px;
}

.social_login_box ul li {
    flex-grow: 1;
}

.social_login_box ul {
    max-width: 440px;
    margin: 0 auto;
    padding-top: 5px;
    margin-bottom: 40px;
}

.dont_have_account_text {
    font-size: 18px;
    margin-top: 60px;
    font-weight: 400;
    margin-bottom: 50px;
}

.dont_have_account_text a {
    color: #3BDE68;
    display: inline-block;
}

.login_form_img img {
    position: absolute;
    top: -190px;
    left: 0;
}

.login_form_img {
    height: 100%;
    width: 100%;
    position: relative;
}

.login_header h2 {
    color: #00D80F;
    font-size: 30px;

    font-weight: 500;
    line-height: 89.4%;
    text-transform: uppercase;
    font-family: 'Bold';
}

.login_header h2 span {
    color: #fff;
    font-weight: 700;
    display: block;
}

.top_step_navigation ul {
    display: flex;
    position: relative;
    max-width: 1190px;
    margin: 0 auto;
}

.top_step_navigation ul li:last-child {
    text-align: right;
    flex-grow: inherit !important;
}

.top_step_navigation li a {
    color: #00D80F;
    font-family: Light;
    font-size: 22px;

    font-weight: 400;
    line-height: 89.4%;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
}

.top_step_navigation {
    margin-top: 70px;
    margin-bottom: 30px;
}

.top_step_navigation li {
    text-align: center;
}

.top_step_navigation li:first-child {
    text-align: left;
    flex-grow: initial !important;
}

.top_step_navigation .step_count {
    color: #797D79;
    font-family: Light;
    font-size: 40px;

    font-weight: 700;
    line-height: 89.4%;
    text-transform: uppercase;
    background: #F0FFF0;
    display: flex;
    width: 60px;
    height: 60px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin-bottom: 20px;
    box-shadow: 0 0 0 20px #0b0b0b;
}

.top_step_navigation ul:before {
    position: absolute;
    content: "";
    left: 120px;
    right: 120px;
    height: 2px;
    background: #07FF00;
    top: 29px;
    z-index: -1;
}

.page_content_box {
    padding: 40px;
    background: #043226;
    border-radius: 30px;
    margin-bottom: 120px;
}

.page_content_box .page_title,
.my_orders_page_ui .page_title {
    color: #FFF;
    font-family: Light;
    font-size: 38px;
    font-weight: 700;
    line-height: 89.4%;
    /* 69.732px */
    text-transform: uppercase;
    margin-bottom: 30px;
}

.page_content_box .page_title span,
.my_orders_page_ui .page_title span {
    color: #00D80F;
    font-weight: 400;
}

.confirmation_page_ui .login_info_box ul li {
    width: auto;
}

.cart_product_box {
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 40px;
    padding-bottom: 40px;
    border-bottom: 2px solid #00d80f;
}


.top_step_navigation .active .step_count {
    background: #00d80f;
    color: #fff;
}

.top_step_navigation .success_step .step_count img {
    max-width: 30px;
}

.cart_product_box .product_descrition {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.product_name_with_buttons .product_title {
    color: #FFF;
    font-family: Light;
    font-size: 28px;
    font-weight: 700;
    line-height: 40px; 
    /* 49.17px */
    text-transform: uppercase;
    padding-bottom: 40px;
}

.product_name_with_buttons ul li a {
    color: #00D80F;
    font-family: Light;
    font-size: 26px;

    font-weight: 500;
    line-height: 89.4%;
    /* 31.29px */
    text-transform: uppercase;
    margin-right: 30px;
    margin-top: 20px;
    display: inline-block;
}

.product_name_with_buttons ul li a {
    margin-top: 20px;
    margin-right: 80px;
}

.product_name_with_buttons {
    flex-grow: 1;
    padding-right: 20px;
}

.product_price_quantity .product_price {
    color: #FFF;
    font-family: Light;
    font-size: 38px;

    font-weight: 700;
    line-height: 89.4%;
    text-transform: uppercase;
    padding-bottom: 40px;
    white-space: nowrap;
}

.quantity-box input {
    width: 70px;
    text-align: center;
    background: transparent;
    border: none;
    color: #ffffff;
    font-family: Light;
    font-size: 27px;

    font-weight: 700;
    line-height: 89.4%;
    /* 49.17px */
    text-transform: uppercase;
}

.quantity-button {
    color: #00D80F;
    font-family: Light;
    font-size: 46px;

    font-weight: 700;
    line-height: 20px;
    /* 49.17px */
    text-transform: uppercase;
    border: none;
    height: 40px;
    width: 40px;
    padding-bottom: 7px;
}

.quantity-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}


.cart_product_box .product_image img {
    width: 280px;
    min-width: 280px;
    max-width: 280px;
}


.coupon_box .position-relative {
    padding-top: 80px;
    max-width: 750px;
    margin: 0 auto;
    padding-bottom: 80px;
}

.coupon_box {
    border-bottom: 2px solid #00d80f;
}

.coupon_box .form-control {
    height: 60px;
    margin-bottom: 20px;
    margin-right: 30px;
    border-bottom: 2px solid #00d80f;
}

.coupon_box .btn {
    white-space: nowrap;
    border: none;
}

.cart_page_summary_box {
    max-width: 750px;
    margin: 0 auto;
    padding-top: 60px;
    padding-bottom: 60px;
}

.cart_page_summary_box .main-title {
    color: #FFF;
    font-family: Light;
    font-size: 36px;

    font-weight: 700;
    line-height: 89.4%;
    /* 49.17px */
    text-transform: uppercase;
    padding-bottom: 40px;
}

.cart_page_summary_box table th {
    color: #00D80F;
    font-family: Light;
    font-size: 26px;

    font-weight: 500;
    line-height: 89.4%;
    /* 31.29px */
    text-transform: uppercase;
    padding: 11px 0;
}

.cart_page_summary_box tr td {
    color: #FFF;
    font-family: Light;
    font-size: 26px;

    font-weight: 700;
    line-height: 64.4%;
    /* 40.23px */
    text-transform: uppercase;
    padding: 11px 0;
}

.checkout_page_btn .btn {
    padding: 10px 45px;
    line-height: 38px;
}

.login_info_box h3 {
    color: #FFF;
    font-family: Light;
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
    text-transform: uppercase;
}

.login_info_box ul li a {
    color: #00D80F;
    font-family: Light;
    font-size: 22px;

    font-weight: 500;
    line-height: 89.4%;
    text-transform: uppercase;
}

.login_info_box ul {
    margin-left: -15px;
    margin-right: -15px;
}

.login_info_box ul li {
    margin-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
    width: 50%; 
}

.login_info_box {
    padding-top: 30px;
    padding-bottom: 30px;
    /* border-bottom: 2px solid #07FF00; */
    padding-left: 15px;
    padding-right: 15px;
}

.login_info_box ul li p {
    color: #00D80F;
    font-family: Light;
    font-size: 22px;

    font-weight: 500;
    line-height: 25px;
    text-transform: uppercase;
    margin-top: 20px;
}

.login_info_box .btn-change {
    min-height: 42px;
    margin-bottom: 10px;
    padding: 4px 33px;
}

.sidebar_cover {
    padding: 30px;
    background: #04231B;
    border-radius: 20px;
    margin-left: 20px;
    height: 100%;
}

.sidebar_cover .title_sidebar {
    color: #FFF;
    font-family: Light;
    font-size: 32px;

    font-weight: 700;
    line-height: 89.4%;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.sidebar_cover .cart_product_box .product_image img {
    width: 120px;
    min-width: 120px;
    max-width: 120px;
}

.sidebar_cover .product_name_with_buttons .product_title {
    font-size: 18px;
    padding-bottom: 10px;
    line-height: 30px;
}

.sidebar_cover .product_price_quantity .product_price {
    color: #00D80F;
    font-size: 22px;
    padding-bottom: 10px;
}

.sidebar_cover .quantity-box {
    justify-content: flex-start;
}

.sidebar_cover .quantity-button {
    font-size: 32px;
    height: 30px;
    width: 30px;
}

.sidebar_cover .quantity-box input {
    width: 35px;
    font-size: 22px;
}

.sidebar_cover .product_name_with_buttons {
    padding-right: 0;
}

.sidebar_cover .cart_product_box {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 20px;
    padding-bottom: 10px;
    border-bottom: none;
}

.sidebar_cover .cart_page_summary_box table th,
.sidebar_cover .cart_page_summary_box table td {
    font-size: 20px;
}

.payment_via_debit .icon img {
    width: 100%;
    max-width: 70px;
    margin-right: 10px;
}

.payment_via_debit h3 {
    color: #00D80F;
    font-family: Light;
    font-size: 26px;

    font-weight: 500;
    line-height: 89.4%;
    text-transform: uppercase;
    margin-top: 50px;
    margin-bottom: 40px;
    display: flex;
    gap: 20px;
    align-items: center;
}

.payment_via_debit .form-control {
    border: 1px solid #00D80F;
    border-radius: 200px;
    height: 60px;
    margin-top: 10px;
    padding: 10px 20px;
}

.payment_via_debit .form-control:focus {
    border: 1px solid #00D80F !important;
}

.payment_via_debit ul {
    margin: 0 -16px;
}

.payment_via_debit ul li {
    padding: 16px;
}

.btn.btn_pay_now {
    white-space: nowrap;
}

.payment_via_debit .remember_password_box label {
    color: #FFF;
    font-family: Regular;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
}

.payment_via_debit .remember_password_box {
    max-width: 550px;
    margin-top: 10px;
}

.payment_method_info img {
    width: 100%;
    max-width: 70px;
}

.terma_condition_box label {
    font-family: Light;
    font-size: 20px;
    text-transform: uppercase;

}

.tottal_order_box .subtottal_ammount p {
    color: #00D80F;
    font-family: Light;
    font-size: 28px;

    font-weight: 700;
    line-height: 89.4%;
    text-transform: uppercase;
}

.tottal_order_box .sub_total_amount {
    color: #FFF;
    font-family: Light;
    font-size: 48px;

    font-weight: 700;
    line-height: 89.4%;
    text-transform: uppercase;
    padding-top: 10px;
}

.tottal_order_box {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    max-width: 880px;
    align-items: flex-end;
    margin: 0 auto;
    padding-top: 70px;
}

.tottal_order_box .subtottal_ammount {
    flex-grow: 1;
    padding: 20px;
}

.tottal_order_box .place_order_button_box {
    padding: 20px;
}

.tottal_order_box .btn_placeorder {
    padding: 12px 50px;
    white-space: nowrap;
}

.thank_you_page_content .subtitle {
    color: #00D80F;
    text-align: center;
    font-family: Light;
    font-size: 22px;

    font-weight: 500;
    line-height: 25px;
    text-transform: uppercase;
}

.thank_you_page_content .cart_page_summary_box tr td {
    font-size: 20px;
    padding-right: 14px;
    vertical-align: top;
    font-weight: 400;
}

.thank_you_page_content .cart_page_summary_box tr th {
    font-size: 20px;
    padding-left: 14px;
    vertical-align: top;
}

.thank_you_page_content .login_info_box h3 {
    font-size: 35px;
}

.purchase_heightlights {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    border-bottom: 2px solid #07FF00;
    padding-bottom: 40px;
    margin-bottom: 30px;
}

.purchase_heightlights li {
    flex-grow: 1;
    padding-top: 25px;
    padding-left: 10px;
    padding-right: 10px;
}

.purchase_heightlights li p {
    color: #FFF;
    font-family: Light;
    font-size: 20px;

    font-weight: 700;
    line-height: 89.4%;
    text-transform: uppercase;
    padding-bottom: 10px;
}

.purchase_heightlights li h4 {
    color: #3BDE68;
    font-family: Light;
    font-size: 20px;

    font-weight: 700;
    line-height: 89.4%;
    /* 17.88px */
    text-transform: uppercase;
}



.product_detail_page_box {
    background: #043226;
    margin-top: 70px;
    padding: 30px;
    border-radius: 20px;
    position: relative;
    z-index: 10;
}

.product_page_detail_description .product_title {
    color: #FFF;
    font-family: Light;
    font-size: 38px;

    font-weight: 700;
    line-height: 89.4%;
    text-transform: uppercase;
    padding-bottom: 0px;
}

.product_page_detail_description .product_price {
    color: #FFF;
    font-family: Light;
    font-size: 38px;

    font-weight: 700;
    line-height: 89.4%;
    text-transform: uppercase;
    padding-bottom: 0px;
    margin-top: 30px;
    margin-bottom: 34px;
}

.product_page_detail_description .product_price span {
    color: #00D80F;
}


.product_page_detail_description p {
    color: #89FFDE;
    font-family: 'Regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-top: 20px;
    text-align: justify;
}

.product_page_detail_description .quantity-box {
    justify-content: flex-start;
    margin-top: 35px;
    margin-bottom: 40px;
}

.product_img_slider_box .product-slider-for img {
    width: 100%;
    object-fit: contain;
    height: 100%;
}

.product_img_slider_box .product-slider-for .slick-slide {
    background: #fff;
    border-radius: 15px;
    padding: 20px;
    height: 460px;
}

.product_img_slider_box .product-slider-nav img {
    width: 100%;
}

.imgLoader {
   width: 102px !important;
}
.product_img_slider_box .product-slider-nav .spacing {
    padding: 10px;
}

.product_page_detail_description {
    padding-left: 20px;
}

.product_img_slider_box .product-slider-nav {
    padding: 10px 40px;
}

.product_img_slider_box .product-slider-nav .slick-prev {
    left: 5px;
}

.product_img_slider_box .product-slider-nav .slick-next {
    right: 18px;
}

.project_full_description p {
    color: #89FFDE;
    font-family: 'Regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-top: 20px;
    text-align: justify;
}

.project_full_description .product_title {
    color: #FFF;
    font-family: Light;
    font-size: 38px;

    font-weight: 700;
    line-height: 89.4%;
    text-transform: uppercase;
    padding-bottom: 0px;
}

.project_full_description {
    margin-top: 60px;
}

.project_full_description .product_title.product_title_border {
    border-bottom: 3px solid #00D80F;
    padding-bottom: 24px;
    margin-bottom: 37px;
}

.product_img_slider_box .product-slider-nav .slick-prev:before {
    font-size: 34px;
    color: #3bde68;
    opacity: 1;
}

.product_img_slider_box .product-slider-nav .slick-next:before {
    font-size: 34px;
    color: #3bde68;
    opacity: 1;
}


.product_detail_page_grids .freshing_green_light_right {
    top: -440px;
}

.product_detail_page_grids .freshing_green_light_left {
    bottom: -270px;
    top: auto;
}

.product-slider-nav .slick-current.slick-active img {
    box-shadow: 0 0 0 6px #00d80f;
    border-radius: 5px;
}

.tracking_slider_ui ul li {
    position: relative;
    padding-top: 60px;
    flex-grow: 1;
    padding-left: 15px;
    padding-right: 15px;
}

.tracking_slider_ui ul li:before {
    position: absolute;
    content: "";
    top: 198px;
    left: 10px;
    right: -10px;
    height: 2px;
    background: #fff;
}

.tracking_slider_ui ul li:after {
    position: absolute;
    content: "";
    top: 189px;
    left: 10px;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background: #3BDE68;
}

.tracking_slider_ui ul li:last-child:before {
    display: none;
}

.tracking_slider_ui ul {
    margin-left: -15px;
    margin-right: -15px;
    position: relative;
}

.tracking_slider_ui ul .icon_with_time .icon img {
    padding-bottom: 10px;
}

.tracking_slider_ui .icon_with_time .date_text {
    color: #3BDE68;
    font-family: Light;
    font-size: 16px;

    font-weight: 500;
    line-height: 28px;
    text-transform: uppercase;
}

.tracking_slider_ui .icon_with_time .date_text span {
    display: block;
}

.tracking_slider_ui .progress_comment h2 {
    color: #FFF;
    font-family: Light;
    font-size: 25px;

    font-weight: 700;
    line-height: 89.4%;
    text-transform: uppercase;
}

.tracking_slider_ui .progress_comment p {
    color: #FFF;
    font-family: Light;
    font-size: 16px;

    font-weight: 300;
    line-height: 89.4%;
    padding-top: 7px;
}

.tracking_slider_ui .progress_comment {
    padding-top: 60px;
}



h4.product_name {
    color: black;
}


.noIteminCart {
    width: 100%;
    height: 40px;
    text-align: center;
    color: white;
    font-weight: 400;
    font-family: Light;
    font-size: 48px;

}

.custom-form-control {
    color: #00D80F;
    border-bottom-color: #00D80F;
}

.custom-form-control:focus {
    border-bottom: 1px solid #00D80F !important;
    color: #00D80F;
}

.message-btn-error {
    color: red;
    font-family: Light;

    font-weight: 500;
    line-height: 89.4%;
    text-transform: uppercase;
    margin-top: 50px;
}

.page-content {
    margin: 3%;
    margin-bottom: 7%;
    color: white;
}




.form-select {

    color: white !important;
    background-color: transparent !important;

}

/* Style for selected suggestion */
input[type="text"]:focus {
    color: white !important;
    background-color: transparent !important;
}

input:-webkit-autofill {
    color: white !important;
    background-color: transparent !important;
}

.form-control {
    color: white !important;
    background-color: transparent !important;
}


.btn.disabled, .btn:disabled, fieldset:disabled .btn {
    color: white !important;
    pointer-events: none;
    border-color: green !important;
    opacity: revert-layer !important;
}



.btn:hover {
    color: white !important;
}


a.logoutDropDown.dropdown-item {
    margin: 0 auto;
    text-align: center;
    background-color: white;
    border-radius: 5px;
    color: black;
    width: 80%;
    margin-top: 8px;
}
.dropdown-item.active, .dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: #087c32;
    margin: 0 auto;
}
.dropdown-item:focus, .dropdown-item:hover {
    color: white;
    background-color: #087c32;
}
.dropdown-menu.show {
    display: block;
    background-color: #087c32;
}



span.a-list-item {
    color: white !important;
}




.classCustom {
    border: none !important;
    border-bottom: 2px solid #00d80f !important;
    border-radius: inherit  !important;
    padding: 4px !important;
}

.content_box_div {
    background: #043226;
    border-radius: 30px;
    margin-top: 0px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 50px;
    margin-bottom: 113px;
    position: relative;
    z-index: 99;
}


.countrySelect {
    border: none;
    border-bottom: 2px solid white;
    border-radius: 0;
}


.slick-slide.slick-active > div {
    height: 100%;
}


.slick-track {
    display: flex !important;
}


body .slick-slide {
    height: auto;
}


.cans_products_slider .product_img {
    height: 340px;
    background:#fff;
}

.cans_products_slider .product_img img {
    position: absolute;
    height: 100%;
    width: 100%;
    background-size: contain;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit:contain;
}


.website-marquee {
    background: linear-gradient(90deg, #11C552 0%, #11C552 100%);
    padding-top: 4px;
    display: none;
}



.LoderSpinnerDiv {
    margin-top: 22vh;
    margin-bottom: 22vh;
    text-align: center;
}



.spinner-border {
    color: white;
}













































































@media only screen and (min-width:992px) {
    .dropdown-menu-item:hover .submenu {
        max-height: 400px;
        overflow-y: auto;
        transition: 0.3s all;
        visibility: visible;
        opacity: 1;
        z-index: 999;
        background: #ffffff;
        width: 100%;
        box-shadow: 0px 2px 15px 0 rgba(0, 0, 0, 0.2);
        border-radius: 2px;
    }

    .dropdown-menu-item:hover .icon img {
        transform: rotate(180deg);
        transition: 0.2s all;
    }

}

@media only screen and (max-width:1420px) {

    .main-menu .brand img {
        max-width: 343px;
        width: 100%;
        margin-left: -11px;
    }

    .menu-items .navbar-nav .nav-link {
        margin: 0 5px;
    }
}


@media only screen and (max-width:1300px) {
    .product_features .btn {
        padding: 5px 4px;
        font-size: 22px;
    }

    .btn {
        font-size: 22px;
    }

    .very_big_title {
        font-size: 64px;
        margin-bottom: -40px;
    }

    .very_big_title span {
        font-size: 34px;
    }

    .why_carabao_section .main_title {
        font-size: 70px;
        max-width: 470px;
    }

    .welcome_influencer_sec p {
        font-size: 25px;
    }

    .welcome_influencer_sec h2 {
        font-size: 58px;
    }

    .welcome_influencer_sec .sub_heading_text {
        font-size: 30px;
    }

    .influencer_why_carabao_sec .main_title {
        font-size: 54px;
        margin-bottom: 40px;
    }

    .reasons_why_carabao_list li h2 {
        font-size: 38px;
    }

    .influencer_how_to_join_sec .header_div p {
        font-size: 22px;
    }

    .influencer_how_to_join_sec .header_div h2 {
        max-width: 180px;
        min-width: 280px;
        font-size: 60px;
    }

    .become_trail_brezzer {
        background-position: right -140px center;
    }
}

@media only screen and (max-width:1250px) {
    .product_page_banner {
        min-height: 590px;
    }

    .our_story_box {
        margin-bottom: 180px;
    }

    .champion_cup img {
        max-width: 460px;
    }

    .our_story_images_adjust .out_story_product_img {
        top: -60px;
        max-width: 680px;
    }

    .our_story_images_adjust {
        margin-top: 0;
    }

    .quick_action_links li a {
        margin-left: 10px;
    }

    .join_our_winning_team_section .contact_us_form {
        margin-top: -280px;
    }

    .login_form_img img {
        top: -130px;
        transform: scale(1.1);
    }

}


@media only screen and (max-width:1199px) {
    .menu-items .navbar-nav .nav-link {
        margin: 0 10px;
    }

    .quick_action_links li a {
        margin-left: 12px;
    }

    .main-menu .brand img {
        max-width: 319px;
    }

    .product_big_img img {
        max-width: 520px;
        margin: 0 auto;
    }

    .product_calories {
        padding-top: 190px;
    }

    .product_features_box {
        margin-top: 300px;
    }

    .very_big_title {
        font-size: 58px;
        margin-bottom: 0;
    }

    .product_img>img {
        min-height: 250px;
    }

    .more_fuel_for_file_sec .product_img {
        min-height: 100%;
    }

    .why_carabao_section {
        padding-top: 30px;
    }

    .newsletter_description h2 {
        font-size: 45px;
    }

    .product_page_banner .product_page_banner_imgs .banner_flash_img {
        top: -64px;
        margin-left: -160px;
    }

    .our_story_box {
        margin-bottom: 120px;
    }

    .brand {
        width: 100%;
    }

    .more_fuel_for_file_sec {
        padding-bottom: 80px;
    }

    .tracking_slider_ui .progress_comment h2 {
        font-size: 20px;
    }

    .tracking_slider_ui ul li {
        padding-top: 30px;
    }

    .tracking_slider_ui ul li:before {
        top: 168px;
    }

    .tracking_slider_ui ul li:after {
        top: 159px;
    }
}

@media only screen and (max-width:1150px) {
    .product_price_quantity .product_price {
        font-size: 28px;
    }

    .product_name_with_buttons .product_title {
        font-size: 28px;
        padding-bottom: 30px;
    }

    .product_name_with_buttons ul li a {
        font-size: 22px;
    }

    .quantity-button {
        font-size: 40px;
        height: 36px;
        width: 34px;
    }

    .cart_product_box .product_image img {
        width: 220px;
        min-width: 220px;
        max-width: 220px;
    }

    .cart_page_summary_box .main-title {
        font-size: 28px;
    }

    .cart_page_summary_box table th,
    .cart_page_summary_box tr td {
        font-size: 22px;
    }

}



@media only screen and (max-width:992px) {
    .main-menu .brand img {
        margin-left: -8px;
    }

    .navbar-light .navbar-toggler {
        margin-left: 16px;
        margin-right: 0px;
    }

    .navbar-div-with-language-btn {
        flex-direction: row-reverse;
        order: 3;
    }

    .brand {
        flex-grow: 1;
    }

    .website-header {
        min-height: 76px;
    }

    .topbar p {
        font-size: 14px;
    }

    .container-fluid {
        z-index: inherit;
    }

    .main-menu .navbar-nav .nav-item>a {
        font-size: 16px;
        color: #fff !important;
        text-align: left;
        border-bottom: 1px solid #615d71;
        width: 100%;
    }

    .menu-items .navbar-nav .nav-link {
        padding: 15px 7px;
    }

    .main-menu .navbar-nav .nav-item.active>a {
        color: #9EEFF4;
    }

    .main-menu .navbar-nav .active>a:before {
        background: #9EEFF4;
    }

    .navbar-collapse .navbar-nav .dropdown-menu {
        background: #f5f5f5;
        padding: 10px;
        border: none;
        color: #333 !important;
        margin: 0;
        border-radius: 0;
    }

    .navbar-collapse .navbar-nav .dropdown-menu a {
        color: #232323 !important;
        padding: 5px 10px;
        margin: 0 0;
    }

    .navbar-toggler i {
        position: absolute;
        transition: 0.2s all;
        color: #fff;
    }

    .navbar-toggler {
        display: inline-block;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        width: 50px;
        font-size: 28px;
        z-index: 999;
        right: 0;
        margin-left: auto;
        margin-bottom: 10px;
        top: 5px;
        right: 0;
        position: relative;
        background: #e11e1b;
    }

    .navbar-toggler i.fa-plus,
    .open_menu .navbar-toggler i.fa-bars {
        opacity: 0;
        transform: rotate(0deg);
    }

    .navbar-toggler i.fa-bars {
        opacity: 1;
        transform: rotate(180deg);
    }

    .open_menu button.navbar-toggler i.fa-plus {
        opacity: 1;
        transform: rotate(225deg);
    }

    .navbar-toggler-icon {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /* .navbar-toggler-icon {
        max-width: 0;
    } */

    .navbar-collapse {
        display: inline-block;
        position: fixed;
        background: #0c4637;
        top: 36px;
        left: 100%;
        height: 100%;
        z-index: 99;
        overflow-y: auto;
        padding: 20px 20px;
        width: 50%;
        max-width: 78%;
        transition: 0.1s all;
        transform: translate(-100%, 0%);
    }

    .open_menu .navbar-collapse {
        transform: translate(0%, 0%);
        transition: 0.1s all;
    }

    .open_menu {
        overflow: hidden;
    }

    .dropdown-menu-item .submenu {
        position: relative;
        background: #433e54;
    }

    .open_submenu .submenu {
        max-height: 500px;
        overflow-y: auto;
        transition: 0.2s all;
        visibility: visible;
        opacity: 1;
    }

    .navbar-light .navbar-nav .nav-link:focus,
    .navbar-light .navbar-nav .nav-link:hover {
        color: rgba(255, 255, 255, .1);
    }

    .navbar-collapse .navbar-nav {
        flex-direction: column;
    }

    .navbar .navbar-nav .submenu li {
        border-bottom: 0.5px solid #615d71;
    }

    .open_menu .overlay-div {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.6);
        z-index: 9;
    }

    .menu-items .navbar-nav .nav-link {

        padding: 15px 12px;
        letter-spacing: 0.08em;
    }

    .dropdown-menu-item .icon img {
        right: 11px;
        top: 19px;
        max-width: 20px;
        width: 100%;
        transition: 0.2s all;
        transform: rotate(0deg);
    }

    .open_submenu .icon img {
        transition: 0.2s all;
        transform: rotate(180deg);
    }

    .product_explore_box .featured_img img {
        max-width: 450px;
        margin: 0 auto;
        display: block;
    }

    .product_big_img {
        width: 40%;
    }

    .product_sale_price,
    .product_features {
        width: 30%;
    }

    .product_calories {
        padding-top: 110px;
    }

    .product_features_box {
        margin-top: 230px;
    }

    .product_box {
        margin: 10px;
    }

    .product_description p {
        text-align: center;
    }

    .btn {
        font-size: 10px;
    }
    .more_fuel_for_file_sec .product_img {
        min-height: 50%;
    }

    .why_carabao_section .main_title {
        margin-bottom: 40px;
    }

    .why_carabao_section .carbas_list_with_icons {
        margin-bottom: 0;
    }

    .instagram_feed_sec .spacing {
        width: 25%;
    }

    .instagram_feed_sec {
        justify-content: center;
    }

    .more_fuel_for_file_sec {
        padding-left: 35px;
        padding-right: 35px;
    }

    .feel_the_freshing_section .featured_img {
        max-width: 300px;
        margin: 0 auto;
        margin-bottom: 40px;
    }

    .feel_freshing_section_bg {
        text-align: center;
    }

    .feel_freshing_description_box .btn.add_to_cart_b {
        margin: 0 auto;
    }

    .feel_freshing_description_box p {
        text-align: center;
    }

    .product_banner_content {
        padding-top: 20px;
        text-align: center;
    }

    .product_banner_content p {
        max-width: 950px;
    }

    .product_page_banner .product_page_banner_imgs .banner_flash_img {
        top: -140px;
        margin-left: 0;
    }

    .our_story_box h3 {
        font-size: 40px;
    }

    .our_story_box {
        margin-bottom: 50px;
    }

    .our_mission_vision_content {
        text-align: center;
    }

    .our_mission_vision_content .main_title {
        max-width: 100%;
    }

    .our_vison_and_mission_section .featured_img img {
        max-width: 510px;
        display: block;
        margin: 0 auto;
        margin-bottom: 40px;
    }

    .why_carabao_section .main_title {
        font-size: 60px;
    }

    .shop_your_way_heading h2 {
        font-size: 28px;
    }

    .company_logos img {
        max-width: 160px;
        margin-bottom: 30px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .our_story_images_adjust .out_story_product_img {
        top: -40px;
        max-width: 560px;
    }

    .champion_cup img {
        max-width: 350px;
    }

    .carabao_cup_section h5 {
        font-size: 18px;
    }

    .carabao_cup_section h2 {
        font-size: 48px;
    }

    .carabao_cup_section p {
        font-size: 16px;
        font-weight: 400;
    }

    .brand {
        width: auto;
    }

    .why_carabao_section.product_why_carabao .main_title.never_ending_energy_heading {
        font-size: 36px;
        text-align: left;
    }

    .why_carabao_section.product_why_carabao .green_text_list .box_icon_with_text h5 {
        text-align: left;
    }

    .feel_freshing_section_bg_sec .featured_img {
        max-width: 100%;
    }

    .why_carabao_section.product_why_carabao .box_icon_with_text {
        flex-direction: column;
        align-items: flex-start;
    }

    .feel_the_freshing_section.feel_freshing_section_bg_sec {
        margin-bottom: 0px;
    }

    .join_our_winning_team_section .floating_carabao_cup_tag img {
        top: -50px;
        max-width: 90px;
    }

    .join_our_winning_team_section .contact_heading h2 {
        font-size: 40px;
    }

    .join_our_winning_team_section .contact_us_form {
        padding: 80px;
    }

    .our_story_section.join_our_winning_team_section {
        padding-left: 50px;
        padding-right: 50px;
    }

    .join_our_winning_team_section .floating_bottle.right_bottle img {
        right: -20px;
    }

    .welcome_influencer_sec {
        text-align: center;
    }

    .welcome_influencer_sec h2 {
        font-size: 42px;
    }

    .welcome_influencer_sec .sub_heading_text {
        font-size: 24px;
    }

    .welcome_influencer_sec p {
        font-size: 20px;
        text-align: center;
    }

    .influencer_why_carabao_sec .main_title {
        font-size: 48px;
    }

    .reasons_why_carabao_list li h2 {
        font-size: 28px;
    }

    .influencer_how_to_join_sec .influencer_form .header_div {
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
    }

    .influencer_how_to_join_sec .header_div h2 {
        max-width: 100%;
        min-width: auto;
        font-size: 40px;
        padding-right: 0;
    }

    .influencer_how_to_join_sec .header_div p {
        font-size: 20px;
        text-align: center;
        padding-top: 20px;
    }

    .influencer_our_requirements_sec .influencer_requirement_row {
        flex-wrap: wrap;
    }

    .influencer_our_requirements_sec .influencer_requirement_row .main-title {
        max-width: 100%;
        padding-right: 0;
        padding-bottom: 30px;
    }

    .influencer_our_requirements_sec {
        padding-bottom: 20px;
    }

    .become_trail_brezzer_box {
        background: rgba(255, 255, 255, 0.7);
        padding: 30px;
    }

    .get_ready_game_sec {
        padding-top: 90px;
        padding-bottom: 90px;
    }

    .get_ready_game_content h2 {
        font-size: 52px;
    }

    .become_trail_brezzer {
        padding-bottom: 130px;
    }

    .login_form_img img {
        top: 0;
        transform: scale(1.2);
    }

    .top_step_navigation li a {
        font-size: 18px;
    }

    .cart_product_box .product_image img {
        width: 160px;
        min-width: 160px;
        max-width: 160px;
    }

    .top_step_navigation .step_count {
        font-size: 30px;
        width: 50px;
        height: 50px;
    }

    .page_content_box .page_title {
        font-size: 30px;
        margin-bottom: 0px;
    }

    .sidebar_cover {
        margin-top: 30px;
        margin-bottom: 40px;
        margin-left: 0;
    }

    .sidebar_cover .title_sidebar {
        font-size: 28px;
    }

    .tottal_order_box .sub_total_amount {
        font-size: 38px;
    }

    .tottal_order_box .subtottal_ammount p {
        font-size: 26px;
    }

    .thank_you_page_content .subtitle {
        font-size: 18px;
    }

    .product_page_detail_description .product_title,
    .project_full_description .product_title {
        font-size: 32px;
    }

    .product_page_detail_description .product_price {
        font-size: 32px;
        margin-top: 20px;
        margin-bottom: 24px;
    }

    .product_page_detail_description p,
    .project_full_description p {
        font-size: 14px;
    }

    .tracking_slider_ui ul li {
        width: 100%;
    }

    .tracking_slider_ui ul {
        flex-wrap: wrap;
    }

    .tracking_slider_ui .progress_comment {
        padding-top: 10px;
    }

    .tracking_slider_ui ul {
        padding-left: 60px;
        margin-top: 50px;
    }

    .tracking_slider_ui ul li:last-child {
        padding-bottom: 0;
    }

    .tracking_slider_ui ul li:before {
        height: auto;
        bottom: -20px;
        top: 20px;
        width: 2px;
        left: -20px;
    }

    .tracking_slider_ui ul li:after {
        top: 14px;
        left: -29px;
    }

    .tracking_slider_ui ul li {
        padding-top: 0;
        padding-bottom: 50px;
    }

}

@media only screen and (max-width:768px) {
    .why_carabao_section .featured_img {
        margin-top: 60px;
    }

    .margin_bottom_adjust {
        bottom: 10px;
    }

    .more_fuel_for_file_sec {
        padding-top: 0;
    }

    .why_carabao_section {
        text-align: center;
    }

    .why_carabao_section .main_title {
        font-size: 50px;
        max-width: 350px;
        padding-bottom: 50px;
        margin: 0 auto;
    }

    .why_carabao_section .box_icon_with_text {
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }

    .why_carabao_section .box_icon_with_text img {
        margin-right: 0;
        margin-bottom: 14px;
    }

    .footer_logo {
        text-align: center;
    }

    .footer_widget .footer_title {
        margin-top: 0;
    }

    .footer_logo img {
        margin-bottom: 0;
    }

    .footer_widget {
        padding-bottom: 30px;
    }

    .contact_us_heading h2 {
        font-size: 34px;
    }

    .contact_us_heading p {
        font-size: 18px;
    }

    .product_sale_price>h1 {
        font-size: 30px;
    }

    .very_big_title {
        font-size: 50px;
    }

    .very_big_title span {
        font-size: 28px;
    }

    .border_bottom_1px {
        flex-direction: column;
    }

    .nav-pills {
        margin-top: 20px;
    }

    .customer_feedback_slider {
        margin-top: 90px;
    }

    .slick-dots li button:before {
        display: none;
    }

    .customer_feedback_slider .slick-prev.slick-arrow {
        top: -45px;
    }

    .customer_feedback_slider .slick-next.slick-arrow {
        top: -46px;
    }

    .text_bigger {
        font-size: 68px;
    }

    .our_blogs_sec .border_bottom_1px {
        align-items: start !important;
    }

    .product_more_fuel_sec {
        padding-top: 50px;
        padding-left: 0;
        padding-right: 0;
    }

    .product_more_fuel_sec .more_fuel_product_list .spacing {
        width: 50%;
    }

    .product_more_fuel_sec .more_fuel_product_list {
        justify-content: center;
    }

    .feel_freshing_heading h2,
    .feel_freshing_description_box h3 {
        font-size: 32px;
    }

    .feel_freshing_heading h3 {
        font-size: 27px;
    }

    .feel_freshing_heading {
        margin-bottom: 30px;
    }

    .feel_freshing_description_box .sub_heading {
        margin-bottom: 20px;
    }

    .our_newsletter_section .featured_img {
        max-width: 300px;
        margin: 0 auto;
    }

    .newsletter_description h2 {
        font-size: 35px;
    }

    .newsletter_description p {
        font-size: 18px;
    }

    .newsletter_description {
        text-align: center;
    }

    .newsletter_form_box {
        margin-top: 0 !important;
    }

    .product_banner_content h2 {
        font-size: 46px;
    }

    .product_banner_content p {
        font-size: 16px;
    }

    .product_banner_content h5 {
        font-size: 24px;
    }

    .product_banner_content {
        padding-top: 20px;
    }

    .feel_the_freshing_section .freshing_green_light_left img {
        display: none;
    }

    .product_page_banner {
        min-height: 410px;
    }

    .our_story_box h3,
    .carabao_cup_section h2 {
        font-size: 34px;
    }

    .why_carabao_about_page .featured_img {
        margin-top: 20px;
    }

    .home_page_banner_content h1 {
        font-size: 38px;
    }

    .home_page_banner_content p {
        font-size: 18px;
    }

    .shop_your_way_sec {
        text-align: center;
    }

    .shop_your_way_heading {
        margin-bottom: 40px;
    }

    .video_play_link {
        right: 20px;
    }

    .product_sale_price,
    .product_features {
        text-align: center;
    }

    .champion_cup img {
        max-width: 240px;
    }

    .our_story_images_adjust .out_story_product_img {
        top: -40px;
        max-width: 420px;
    }

    .our_story_images_adjust .zigzag_img img {
        margin-top: 50px;
    }

    .our_story_box {
        margin-bottom: 100px;
    }

    .why_carabao_section.product_why_carabao .main_title.never_ending_energy_heading {
        text-align: center;
    }

    .why_carabao_section.product_why_carabao .box_icon_with_text {
        align-items: center;
    }

    .why_carabao_section.product_why_carabao .green_text_list .box_icon_with_text h5 {
        text-align: center;
    }

    .join_our_winning_team_section .contact_us_form {
        margin-top: -160px;
        margin-bottom: 230px;
    }

    .join_our_winning_team_section .contact_heading h2 {
        font-size: 34px;
    }

    .join_our_winning_team_section .trade_enquery_heading h4 {
        font-size: 30px;
    }

    .welcome_influencer_sec h2 {
        font-size: 32px;
        margin-top: 14px;
    }

    .welcome_influencer_sec p {
        font-size: 18px;
        margin-top: 10px;
    }

    .influencer_why_carabao_sec {
        padding-top: 80px;
    }

    .reasons_why_carabao_list li {
        width: 100%;
        padding-left: 0px;
    }

    .reasons_why_carabao_list li:before {
        font-size: 80px;
    }

    .reasons_why_carabao_list li:nth-child(2) {
        border-top: 1px solid #373737;
    }

    .influencer_how_to_join_sec {
        padding-top: 50px;
    }

    .influencer_how_to_join_sec .header_div p {
        font-size: 18px;
        padding-top: 15px;
    }

    .influencer_our_requirements_sec .influencer_requirement_row .main-title {
        font-size: 38px;
    }

    .become_trail_brezzer_box .main-title {
        font-size: 38px;
    }

    .influencer_form {
        padding: 40px 20px;
    }

    .login_form_img img {
        display: none;
    }

    .login_form_area .hurdle_layer img {
        left: 0;
        width: 100%;
    }

    .our_story_section.login_form_area {
        margin-top: -190px;
    }

    .forgot_password_link {
        font-size: 16px;
    }

    .cart_product_box {
        flex-direction: column;
    }

    .product_name_with_buttons ul li a {
        margin-right: 40px;
    }

    .product_name_with_buttons .product_title {
        padding-bottom: 10px;
    }

    .product_price_quantity .product_price {
        padding-bottom: 20px;
    }

    .sidebar_cover .cart_product_box {
        flex-direction: row;
    }

    .login_info_box {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 20px;
        padding-bottom: 20px;

    }

    .sidebar_cover .product_name_with_buttons .product_title {
        font-size: 22px;
    }

    .login_info_box h3,
    .page_content_box .page_title {
        font-size: 28px;
    }

    .login_info_box ul li p,
    .login_info_box ul li a {
        font-size: 18px;
    }

    .tottal_order_box {
        padding-top: 30px;
    }

    .thank_you_page_content .subtitle {
        font-size: 16px;
    }

    .product_img_slider_box {
        margin-bottom: 50px;
    }

    .product_page_detail_description {
        padding-left: 0px;
    }

    .product_page_detail_description .product_title,
    .project_full_description .product_title {
        font-size: 26px;
    }

    .product_detail_page_box {
        padding: 20px;
    }

}

@media only screen and (max-width:680px) {
    .product_price_image_features {
        flex-wrap: wrap;
        flex-direction: column;
    }

    .product_big_img img {
        position: relative;
        top: 0;
    }

    .product_sale_price,
    .product_features {
        width: 100%;
    }

    .product_features_box {
        margin-top: 0px;
    }

    .product_calories {
        padding-top: 40px;
    }

    .product_big_img {
        width: 100%;
    }

    .product_explore_box {
        border-radius: 0 0px 35px 35px;
    }

    .very_big_title {
        margin-top: 0;
    }

    .instagram_feed_sec .spacing {
        width: 33.33%;
    }

    .product_page_banner .product_page_banner_imgs .banner_flash_img {
        top: -80px;
    }

    .product_page_banner .main_product_bottle_big img {
        transform: translateX(-50%);
    }

    .why_carabao_section .main_title {
        font-size: 40px;
    }

    .our_mission_vision_content .main_title {
        font-size: 38px;
    }

    .our_story_box {
        padding-top: 30px;
    }

    .join_our_winning_team_section .floating_bottle img {
        left: -36px;
        max-width: 90px;
        top: 70px;
    }

    .our_story_section.join_our_winning_team_section {
        padding-left: 30px;
        padding-right: 30px;
    }

    .join_our_winning_team_section .floating_bottle.right_bottle img {
        bottom: -80px;
    }

    .join_our_winning_team_section .contact_us_form {
        padding: 50px;
    }

    .join_our_winning_team_section .floating_carabao_cup_tag img {
        top: -50px;
        max-width: 60px;
        right: 30px;
    }

    .tottal_order_box {
        margin: 0 -15px;
        flex-direction: column;
    }

    .tottal_order_box .subtottal_ammount {
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
    }

    .tottal_order_box .place_order_button_box {
        padding-left: 10px;
        width: 100%;
    }

    .product_detail_page_grids .freshing_green_light_right,
    .product_detail_page_grids .freshing_green_light_left,
    .product_detail_page_grids .freshing_green_light_right img,
    .product_detail_page_grids .freshing_green_light_left img {
        max-width: 80%;
    }


}

@media only screen and (max-width:620px) {
    .payment_via_debit ul {
        flex-wrap: wrap;
    }

    .payment_via_debit ul li {
        width: 100%;
    }

}

@media only screen and (max-width:575px) {

    .footer_widget {
        text-align: center;
    }

    .social_media_links {
        justify-content: center;
    }

    .footer_logo img {
        max-width: 190px;
    }

    .margin_bottom_adjust .btn {
        font-size: 18px;
    }

    .instagram_feed_sec .spacing {
        width: 50%;
    }

    .product_more_fuel_sec .more_fuel_product_list .spacing {
        width: 100%;
    }

    .product_page_banner .product_page_banner_imgs .banner_flash_img {
        top: -30px;
    }

    .our_story_images_adjust .zigzag_img img {
        margin-top: 10px;
    }

    .our_story_section {
        margin-bottom: 130px;
    }

    .home_page_banner_content {
        position: relative;
        top: 20px;
        left: 0;
        transform: translate(0, 0%);
        width: auto;
    }

    section.shop_your_way_sec {
        padding-bottom: 30px;
    }

    .our_story_images_adjust .out_story_product_img {
        max-width: 300px;
    }

    .champion_cup img {
        max-width: 180px;
        top: 30px;
    }

    .join_our_winning_team_section .contact_heading h2 {
        font-size: 28px;
    }

    .join_our_winning_team_section .contact_heading p {
        font-size: 16px;
    }

    .join_our_winning_team_section .trade_enquery_heading h4 {
        font-size: 28px;
    }

    .welcome_influencer_sec p {
        line-height: 28px;
    }

    .influencer_how_to_join_sec .header_div h2 {
        font-size: 30px;
    }

    .influencer_our_requirements_sec .requirement_list_box li {
        width: 100%;
    }

    .become_trail_brezzer_box .main-title {
        font-size: 32px;
    }

    .become_trail_brezzer_box p {
        font-size: 18px;
    }

    .influencer_why_carabao_sec .main_title {
        font-size: 42px;
    }

    .get_ready_game_content h2 {
        font-size: 32px;
    }

    .cart_product_box .product_descrition {
        flex-direction: column;
        align-items: flex-start;
    }

    .product_price_quantity {
        margin-top: 30px;
    }

    .top_step_navigation li a {
        font-size: 16px;
    }

    .sidebar_cover .product_price_quantity {
        margin-top: 0px;
    }

    .sidebar_cover .cart_product_box .product_image img {
        width: 90px;
        min-width: 90px;
        max-width: 90px;
    }

    .thank_you_page_content .login_info_box h3 {
        font-size: 28px;
    }

    .thank_you_page_content .cart_page_summary_box tr th,
    .thank_you_page_content .cart_page_summary_box tr td {
        font-size: 16px;
        padding-left: 0;
    }

    .thank_you_page_content .sidebar_cover .title_sidebar {
        font-size: 26px;
    }

    .my_orders_page_ui .purchase_heightlights li {
        width: 100%;
    }
}

@media only screen and (max-width:530px) {
    .brand {
        flex-grow: 1;
        width: 100%;
        text-align: center;
    }

    .newsletter_form_box {
        flex-direction: column;
    }

    .newsletter_form_box .btn {
        margin-top: 30px;
    }

    .our_story_section {
        margin-bottom: 100px;
    }

    .product_name_with_buttons .product_title {
        font-size: 22px;
        padding-bottom: 0px;
    }

    .product_price_quantity .product_price {
        font-size: 22px;
    }

    .product_name_with_buttons ul li a {
        font-size: 18px;
    }

    .quantity-box {
        justify-content: flex-start;
    }

    .coupon_box .position-relative {
        flex-direction: column;
    }

    .coupon_box .form-control {
        margin-right: 0;
    }

    .coupon_box .btn {
        width: 100%;
    }

    .cart_page_summary_box table th,
    .cart_page_summary_box tr td {
        font-size: 18px;
    }

    .sidebar_cover .cart_product_box {
        flex-direction: column;
    }

    .sidebar_cover .product_name_with_buttons {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .product_img_slider_box .product-slider-for .slick-slide {
        height: auto;
    }

    .navbar-collapse {
        top: 36px;
        width: 100%;
        max-width: 100%;
    }
}

@media only screen and (max-width:480px) {

    .instagram_feed_sec .spacing {
        width: 100%;
    }

    .product_page_banner {
        min-height: 310px;
    }

    .champion_cup img {
        max-width: 120px;
    }

    .our_story_images_adjust .out_story_product_img {
        max-width: 240px;
    }

    .why_carabao_section.product_why_carabao .carbas_list_with_icons li {
        width: 100%;
    }

    .feel_freshing_section_bg_sec .feel_freshing_section_bg {
        padding-left: 0;
        padding-right: 0;
    }

    .join_our_winning_team_section .floating_bottle img {
        display: none;
    }

    .our_story_section.join_our_winning_team_section {
        padding-left: 0;
        padding-right: 0;
    }

    .join_our_winning_team_section .contact_us_form {
        margin-top: -160px;
        margin-bottom: 120px;
        padding: 30px;
        padding-top: 50px;
    }

    .welcome_influencer_sec p {
        font-size: 16px;
    }

    .influencer_why_carabao_sec .main_title {
        font-size: 38px;
    }

    .reasons_why_carabao_list li h2 {
        font-size: 22px;
    }

    .reasons_why_carabao_list li:before {
        font-size: 54px;
    }

    .influencer_how_to_join_sec .header_div p {
        font-size: 16px;
        line-height: 28px;
    }

    .influencer_our_requirements_sec .influencer_requirement_row .main-title {
        font-size: 30px;
    }

    .become_trail_brezzer_box .main-title {
        font-size: 28px;
    }

    .become_trail_brezzer_box p {
        font-size: 16px;
    }

    .become_trail_brezzer_box .join_now_btn {
        padding: 5px 40px;
    }

    .get_ready_game_sec {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .coupon_box .btn {
        min-height: 52px;
    }
}


@media only screen and (max-width:440px) {

    .page_content_box {
        padding: 20px;
        padding-bottom: 40px;
    }

    .cart_product_box {
        padding-left: 0;
        padding-right: 0;
    }

    .top_step_navigation .step_count {
        font-size: 24px;
        width: 40px;
        height: 40px;
        box-shadow: 0 0 0 15px #0b0b0b;
    }

    .top_step_navigation ul:before {
        left: 50px;
        right: 60px;
        top: 19px;
    }

    .top_step_navigation li a {
        font-size: 12px;
    }

    .page_content_box .page_title {
        font-size: 27px;
        margin-bottom: 0px;
        padding-top: 10px;
    }

    .cart_page_summary_box table th,
    .cart_page_summary_box tr td {
        font-size: 16px;
    }
}